import React from 'react'
import styled from '@emotion/styled'
import { GlobalStyles } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'

const verkkokauppaTheme = createTheme({
  palette: {
    primary: {
      main: '#007864',
    },
    secondary: {
      main: '#E9FAF2',
    },
    error: {
      main: '#F35252',
    },
    info: {
      main: '#0091ED',
    },
    success: {
      main: '#48A04C',
    },
  },
  components: {
    // Name of the component

    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'white',
          },
        },
      },
    },
  },
})
declare module '@mui/material/styles' {
  type Theme = typeof verkkokauppaTheme
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

interface PageData {
  element: React.ReactElement
}

// Redirect Firebase default domains to proper domain.
if (typeof window !== 'undefined') {
  if (
    window.location.hostname === 'mehi-lahjakorttihallinta-prod.web.app' ||
    window.location.hostname === 'mehi-lahjakorttihallinta-prod.firebaseapp.com'
  ) {
    window.location.href = 'https://lahjakorttihallinta.mehilainen.fi/'
  }
}

export default ({ element }: PageData) => {
  return (
    <>
      <ThemeProvider theme={verkkokauppaTheme}>
        <GlobalStyles
          styles={`
            body {
              font-family: 'Montserrat', sans-serif;
              overflow-x: hidden;
              margin: 0;
            }
            .MuiTypography-root
            {
              font-family: 'Montserrat', sans-serif !important;
              
            }
            a {
              text-decoration: none;
              color: black;
              :hover {
                color: green;
                transition: color 0.2s ease;
              }
            }

           
            .MuiTypography-h6 {
              text-align: left !important;
              font-family: 'Montserrat', sans-serif;
            }

            @media (max-width: 200px) {
              h1 {
                font-size: 27px !important;
              }

              h2 {
                font-size: 22px !important;
              }
            }
          `}
        />
        <Wrapper>
          <div>{element}</div>
        </Wrapper>
      </ThemeProvider>
    </>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  margin-top: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
